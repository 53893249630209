h1.category-title {
        font-weight: 300;
}
.services_h_mod .full-text-wrapper p {
    padding: 30px 0;
}
.items-block.events-list .card .mod-intro-text {
        margin-bottom: 5px;
}

.events-list b.no-results {
        font-size: 1.6rem;
}

.events-list b.no-results i {
        color: #ccc;
}

.items-block.cards-list .card h3 {
        /*border-bottom: 1px solid #D9D9D9;*/
        padding: 0;
        margin: 0;
}

.items-block.cards-list .card .card-text {
        position: relative;
        padding-top: 15px;
        margin-top: 10px;
}

.items-block.cards-list .card .card-text:before{
        position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: #ddd;
    content: '';
}


.items-list-default .subcategories-list .subcategory > a > i {
        font-size: 40px;
        line-height: 48px;
        color: #5d5d5d;
}

.items-list-default .subcategories-list .subcategory-title {
        margin: 0;
        font-weight: 400;
        line-height: 1.6rem;
}

.items-list-default .subcategories-list .subcategory-title b {
        font-weight: 700;
        display: block;
        color: #5d5d5d;
}

.item-title.page-title {
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
}

.item-title.page-title small {
        display: block;
        font-size: 1rem;
        color: #b7b7b7;
        margin-top: 5px;
}

.item-title.page-title .edit-article-button {
        font-size: 1rem;
        left: auto;
        right: -15px;
}

.item-created-time {
        margin-bottom: 15px;
        font-weight: 500;
        color: #7EA73F;
}

.item-content .item-image {
        /*margin-bottom: 15px;*/
}

.video-list.items-block.cards-list .card h3 {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
}

.video-list.items-block .card-label:hover {
        cursor: pointer;
        background: #ed1c24;
}

.event-page h1.item-title {
        margin-bottom: 15px;
}

.event-page .item-body {
        border-bottom: 1px solid rgba(126,167,63,1);
        box-shadow: 0 0 1px rgba(126,167,63,1);
        padding: 1.25rem;
}

.event-page .item-body h2.subject {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 1.1rem;
        line-height: 1.5rem;
}

.event-page .event-details {
        border-bottom: 1px solid rgba(126,167,63,1);
        box-shadow: 0 0 1px rgba(126,167,63,1);
}

.event-page .map {
        height: 290px;
        overflow: hidden;
        position: relative;
}

.event-page .map-address {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px 30px;
        background: rgba(255,255,255,0.7);
        color: #0e0f11;
        font-size: 0.7rem;
}

.event-page .map-address i {
        opacity: 0.5;
        margin-right: 5px;
        font-size: 1rem;
}

.map-rendered {
        width: 100%;
        height: 110%;
}

.event-page .event-data {
        padding: 30px;
}

.event-page .event-data ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
}

.event-page .event-data ul li {
        margin-bottom: 10px;
        font-size: 0.8rem;
        word-wrap: break-word;
}

.event-page .event-data ul li i {
        margin-right: 5px;
        color: #7ea73f;
}

.event-page .event-data ul span {
        font-weight: 700;
        display: block;
}

.academia-page h1.item-title {
        margin-bottom: 15px;
        font-size: 1.2rem;
        line-height: 1.7rem;
}

.academia-page .item-body {
        border-bottom: 1px solid rgba(126,167,63,1);
        box-shadow: 0 0 1px rgba(126,167,63,1);
        padding: 1.25rem;
}

.academia-page .item-body h2.subject {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 1.1rem;
        line-height: 1.5rem;
}

.academia-page .item-body .item-content > h3 {
        font-size: 1rem;
}

.academia-page .item-body .item-content > ul {
        list-style: none;
        padding: 0;
}

.academia-page .item-body .item-content > ul p {
        font-size: 0.8rem;
        margin-bottom: 5px;
}

.academia-page .item-body .item-content > ul > li {
        font-size: 0.8rem;
}

.academia-page .item-body .item-content > ul > li i {
        margin-right: 5px;
        color: #7ea73f;
}

.academia-page .item-body .item-content > ul ol,
.academia-page .item-body .item-content > ul ul {
        padding-left: 15px;
}

.academia-page .item-body .item-content span.title {
        display: block;
        font-weight: 700;
}

.academia-page .academia-details {
        border-bottom: 1px solid rgba(126,167,63,1);
        box-shadow: 0 0 1px rgba(126,167,63,1);
}

.academia-page .map {
        height: 290px;
        overflow: hidden;
        position: relative;
}

.academia-page .map-address {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px 30px;
        background: rgba(255,255,255,0.7);
        color: #0e0f11;
        font-size: 0.7rem;
}

.academia-page .map-address i {
        opacity: 0.5;
        margin-right: 5px;
        font-size: 1rem;
}

.academia-page .academia-data {
        padding: 30px;
}

.academia-page .academia-data ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
}

.academia-page .academia-data ul li {
        margin-bottom: 10px;
        font-size: 0.8rem;
        word-wrap: break-word;
}

.academia-page .academia-data ul li i {
        margin-right: 5px;
        color: #7ea73f;
}

.academia-page .academia-data ul span {
        font-weight: 700;
        display: block;
}

.academia-page .academia-data hr {
        display: block;
        border-top: 1px solid rgba(0,0,0,0.1);
        width: 100%;
        margin: 0 15px 15px;
}

#map-infobox .modal-lg {
        max-width: 1100px;
        max-height: 90%;
}

.item-share {
        padding: 15px 0;
        margin-bottom: 15px;
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
}

.item-share a {
        color: #7EA73F !important;
}

.item-share a:hover {
        cursor: pointer;
}

.item-share a > i {
        border: 1px solid #7EA73F;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        margin-right: 10px;
}

.attachments {
        list-style: none;
        padding: 0;
        margin-top: 15px;
}

.attachments li {
        display: inline-block;
        margin-right: 5px;
}

.attachments li a {
        border: 1px solid rgba(0,0,0,0.1);
        padding: 5px 10px;
        font-size: 1rem;
}

.attachments li a i {
        margin-right: 5px;
}

.box h3 {
        font-size: 1.3rem;
        line-height: 1.6rem;
}

.box.contact-details h2 {
        font-size: 1.2rem;
        margin: 0;
        padding: 15px;
        background: #7EA73F;
        color: #fff;
}

.box.contact-details .content {
        padding: 15px;
}

.box.contact-details .content p {
        margin-bottom: 5px;
        font-size: 0.8rem;
}

.box.contact-details .content .description {
        margin-bottom: 15px;
}

.box.contact-details .content .data-line {
        padding-bottom: 8px;
}

.box.contact-details .content .data-line i {
        width: 20px;
        color: #7ea73f;
}

.contact-page .map {
        height: 450px;
        overflow: hidden;
}

.category-description {
        margin-bottom: 0;
        overflow: hidden;
}

.category-content{
        margin-bottom: 30px;
}

.fadeout {
        position: relative;
        bottom: 2.5rem;
        height: 3rem;
        background: -webkit-linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%);
        background-image: -moz-linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%);
        background-image: -o-linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%);
        background-image: linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%);
        background-image: -ms-linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%);
    }

    .cat_desc_read_more{
            border-top: 1px solid #ddd;
            font-size: 2.2rem;
            cursor: pointer;
    }

.item-gallery h4,
.item-video h4{
        margin-top: 15px;
        border-bottom: 3px solid #7EA73F;
}

.item-gallery h4 span,
.item-video h4 span {
        background: #7EA73F;
        font-size: 1rem;
        padding: 0.4rem 0.7rem;
        display: flex;
        width: 110px;
        margin-bottom: -3px;
}

.item-gallery h4 span i,
.item-video h4 span i {
        margin-right: 5px;
}

.item-video .embed-responsive {
        margin: 23px 0;
}

.aboutus-page .item-text ul {
        display: flex;
        padding: 0;
        margin: 15px 0;
        list-style: none;
}

.aboutus-page .item-text ul li {
        width: 50%;
        border: 1px solid #7EA73F;
        padding: 15px;
        display: flex;
        align-items: center;
}

.aboutus-page .item-text ul li:first-child {
        margin-right: 15px;
}

.aboutus-page .item-text ul li:first-child:before {
        font-family: 'FontAwesome';
        content: '\f1d8';
        color: #7EA73F;
        font-size: 2rem;
        margin-right: 10px;
}

.aboutus-page .item-text ul li:last-child {
        margin-left: 15px;
}

.aboutus-page .item-text ul li:last-child:before {
        font-family: 'FontAwesome';
        content: '\f164';
        color: #7EA73F;
        font-size: 2rem;
        margin-right: 10px;
}

.one_article_inner{
        position: relative;
}

.article-info{
        position: absolute;
        bottom: -100px;
        background: #202020;
        color: #fff;
        width: 80%;
        padding:  2.375rem 1rem;
        left: 10%;
        transition: 0.1s;
}


.member_card{
        background: #202020;
        color: #fff;
        padding: 1.5rem 1rem;
}

.member_card .item-introtext{
        font-size: 1.188rem;
        color: #878787;
}

.member_card .member-title{
        font-size: 1.375rem;
        font-weight: 400;
        letter-spacing: 5px;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
}

.member_card .member-image-credits{
        font-size: 1.188rem;
        color: #878787;
        margin-bottom: 1rem;
}


.about_us_page .card-text{
        font-size: 1.188rem;
        color: #878787;
        margin-bottom: 1rem;
}



.about_us_page .mod-item-heading{
        font-size: 1.375rem;
        font-weight: 400;
        letter-spacing: 5px;
}

.social_block{
        text-align: center;
}

.social_block i {
        padding: 0 0.5rem;
}

.social_block a:hover{
        color: #fff;
}

.about_us_page  .item.article{
        margin-bottom: 11rem !important;
}

.sub_element a{
        background: #efefef;
        height: 350px;
        padding: 1.5rem;
}

.sub_element a:hover{
        color: #222222;
}

.sub_element i {
        font-size: 3rem;
        margin-bottom: 2.5rem;
        color: #444444;
}

.sub_element_a_inner{
        margin: auto;
}

.services_content .subcategory-title, .services_content .item-title{
        font-size: 1rem;
        color: #222222;
        font-weight: 700;
        letter-spacing: 3px;
        line-height: 2rem;
}

.cat-items-row{
        margin-top: 5rem;
}

.item-page.clients_page .item-body{
        padding-top: 8rem;
        padding-bottom: 8rem;
}

.item-page.clients_page.blog_page .item-body{
        padding-top: 2.25rem;
        padding-bottom: 6rem;
}

.item-page.clients_page.blog_page .item-bottom.row{
        border-top: 1px solid #ddd;
}

.achievement_block{
        background: #f7f7f7;
        color: #222222;
        padding-top: 5.5rem;
        padding-bottom: 6rem;
}
.ach_h_title{
        color: #212121;
        text-transform: uppercase;
        margin-bottom: 2.2rem;
        font-weight: 300;
        letter-spacing: 8px;
        line-height: 3rem;
}

.ach_h_title .title_spans:before{
        content: url(../images/customTitleLogo.png);
        margin-right: 10px;
        border: 1px solid #dddddd;
        padding: 10px 3px 0px 3px;

}

.ach_h_title .title_spans span:first-child, .testim_title .title_spans span:first-child{
        font-weight: 900;
}

.achievement_description{
        font-size: 1rem;
        color: #444444;
        line-height: 1.9rem;
        margin-bottom: 4rem;
}

.one_achievement:after {
        content: '';
        height: 100px;
        width: 1px;
        background-color: #dddddd;
        position: absolute;
        right: 0;
        top: 35px;
}

.one_achievement:last-child::after{
        content: none;
}

.ach_icon{
        font-size: 3rem;
        margin-bottom: 1.5rem;
}

.ach_title{
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1.2rem;
}

.ach_desc{
        font-size: 3rem;
        font-weight: 900;
}

.testimonial_block{
        background-color: #2b2b2b;

}

.testim_title{
        color: #fff;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 3rem;
        letter-spacing: 8px;
}

.testimonial_text{
        color: #1f1f1f;
        background-color: #fff;
        font-size: 1.25rem;
        padding: 2.5rem 1.25rem;
        line-height: 2rem;
        margin-bottom: 2rem;
}

.testimonial_author{
        font-size: 1.125rem;
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
}

.testimonial_client{
       color: #fff;
}

.testimonial_backgrond{
        background: url(../images/bkg3.png) 110% -280px, url(../images/bkg4.png) 120px 100px;
        background-repeat: no-repeat;
        padding-top: 5.5rem;
        padding-bottom: 8rem;

}

.item-bottom{
        padding-top: 7rem;
        padding-bottom: 7rem;
}



/* clients page ++++++++++++++++++++++++ */

body.clients_page #k2-container.cards-list{
        padding-top: 6.25rem;
        padding-bottom: 7rem;
        background-color: #f3f3f3;
}

.items-list .card{
        border-radius: 0;
        border: none;
}

.items-list .card:hover{
        color: none;
}

.blog-items .category-description{
        margin-bottom: 4rem;
}

.items-list .card-body{
        padding: 1.8rem;
}

.items-list .card-time, .items-list .card-category{
        color: #aaa;
        display: inline-block;
        font-size: 0.875rem;
        margin-bottom: 1rem;
}

.items-list .card-title{
        font-size: 1rem;
        line-height: 1.5rem;
        color: #222222;
        font-weight: 700;
        letter-spacing: 0.2rem;
        margin-bottom: 1rem;

}

.about_us_page .items-list .mod-item-heading {
        font-size: 1.375rem;
        font-weight: 400;
        letter-spacing: 5px;
        color: #fff;
    }



.card-image{
        position: relative;
}
.card_overlay{
        background: rgba(43, 43, 43, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: block;
        color: #FFF;
        transition: opacity .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out;
        -webkit-transition: opacity .2s ease-in-out;
}

.card:hover .card_overlay{
        opacity: 1;
        transition: opacity .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out;
        -webkit-transition: opacity .2s ease-in-out;
}

.card_overlay i{
        font-size: 3rem;
        margin: auto;
}



/* clinets page --------------------- */


/* blog page ++++++++++++++++++ */

body.clients_page.blog_page #k2-container.cards-list{
        padding-top: 2.25rem;
        padding-bottom: 7rem;
        background-color: #f3f3f3;
}

/* blog page ---------------------*/


/* contacts+++++++++++++++++++++++++ */

.contacts-items .contact_icon{
        font-size: 3rem;
        margin-right: 1rem;
        padding-left: 2rem;
}

.contacts-items .items-list-primary .item.article.first_item .contact_icon{
        padding-left: 0;
}


.contact_info{
        font-size: 1.375rem;
        margin: auto 0;
        font-weight: 400;
        line-height: 2rem;
}

.contact_info p{
        margin: 0;
}

.contacts-items .item.article{
        border-right: 1px solid #ddd;

}

.contacts-items .item.article.last_item{
        border: none;

}


/* contact-------------------------- */


/*Landing page begin ++++++++++++++++++++++++++++++++++++++++++++++++++++*/

body.landing_page .services_content .row.cat-items-row{
    margin: 0;
}

body.landing_page  #content-row{
    padding-bottom: 2rem;
}

.landing_page_title{
    font-size: 2.25rem;
    line-height: 2.625rem;
    font-weight: 500;
    margin-bottom: 40px;
    color: #444444;
}

body.landing_page .category-description p{
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}

.customModuleTitle{
    font-size: 3rem;
    color: #202020;
    margin-bottom: 30px;
    text-align: left;
}
.services_h_three_mod {
    padding-bottom: 50px;
}

body #service-landing-a-row h2.mod-item-heading .mod-item-title b{
    font-weight: 700;
}

#service-landing-a-row .mod-intro-text{
    line-height: 32px;
}

#service-landing-a-row .mod-intro-text strong{
    font-weight: 700;
}

#service-landing-a-row .left-col{
    padding-bottom: 55px;
}

.customLogo .customModuleTitle::before{
        padding: 0px 6px 0px 8px;
}

#service-landing-a-row .title_line:before,
#service-landing-b-row .title_line:before,
#service-landing-c-row .title_line:before,
#service-landing-d-row .title_line:before,
#service-landing-e-row .title_line:before,
#service-landing-f-row .title_line:before,
#service-landing-g-row .title_line:before,
#service-landing-h-row .title_line:before,
#service-landing-i-row .title_line:before,
#service-landing-l-row .title_line:before,
#service-landing-n-row .title_line:before{
    top: 27px;
}
/*
#service-landing-a-row {
    padding-top: 2rem;
    padding-bottom: 2rem;
}*/

#service-landing-b-row .mod-item-heading .mod-item-title{
    font-size: 1.25rem;
    font-weight: 500;
    color: #444;
    margin-bottom: 32px;
    line-height:  2.25rem;
    text-align: left;
}

#service-landing-b-row .mod-intro-text{
    font-size: 0.875rem;
    line-height: 1.625rem;
    margin-bottom: 50px;
}

.faq-card{
    margin-bottom: 20px;
    border-radius: 0;
    border: none;
}

.faq-head{
    cursor: pointer;
    padding: 15px 20px;
     background: #eeeeee;
     color: #232323;
     font-size: 1rem;
}

.faq-head:not(.collapsed){
    background: #222222;
     color: #fff;
     font-size: 1.125rem;
}


.faq-body .intro-text{
    padding: 15px 20px;
}


        /*accordion+++*/

.accordion_header {
    cursor: pointer;
    position: relative;
    cursor: pointer;
    padding: 15px 30px 15px 60px;
    background: #eeeeee;
    color: #232323;
    font-size: 1rem;
    max-height: 102px;
    height: 100%;
}
.service-landing-c .js-accordion, 
.service-landing-d .js-accordion, 
.service-landing-k .js-accordion, 
.service-landing-h .js-accordion,
.service-landing-e .js-accordion {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
}

.service-landing-h .services_h_three_mod .js-accordion {
    grid-template-columns: auto auto auto;
}

.service-landing-c .js-accordion section .accordion_header{
    max-height: 100px;
}
.service-landing-h .js-accordion section .accordion_header{
    max-height: 50px;
}

.service-landing-h .services_h_three_mod .js-accordion section .accordion_header{
    max-height: 100px;
}

.js-accordion section.expanded .accordion_header, .js-accordion section.expanded .accordion_header {
    background: #222222;
    color: #fff;
}
.why_rats_mod section.expanded .accordion_header{
    display: flex;
    align-items: center;
}

.js-accordion section .accordion_header:before{
    font-family: 'FontAwesome';
    content: '\f105';
    color: #232323;
    font-size: 20px;
    position: absolute;
    left: 21px;
    top: 50%;
    margin-top: -12px;
    border: 1px solid #232323;
    border-radius: 53%;
    width: 23px;
    height: 23px;
    padding-left: 8px;
    line-height: 21px;
}

.js-accordion section .no_icon::before {
    opacity: 0;
}

.service-our-experience tr td {
    border: 1px solid #dddddd;
    padding: 10px;
}

.js-accordion section.expanded .accordion_header:before{
        color: #fff;
        content: '\f107';
        border: 1px solid #fff;
        padding-left: 4px;
    }

.js-accordion.js>:not(.expanded)>:not(.accordion_header) {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
}

.js-accordion.js>.expanded>:not(.accordion_header) {
    max-height: 1080px;
    opacity: 1;
    visibility: visible;
}

.js-accordion section.expanded .accordion_content {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 2px solid #222222;
}

.js-accordion.js>*>:not(.accordion_header) {
    -webkit-transition: max-height .5s,visibility .5s,margin .5s,opacity .5s;
    transition: max-height .5s,visibility .5s,margin .5s,opacity .5s;
}

.accordion_content:not(.collapsed) {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.js-accordion.js>*>:not(.accordion_header) {
    -webkit-transition: max-height .5s,visibility .5s,margin .5s,opacity .5s;
    transition: max-height .5s,visibility .5s,margin .5s,opacity .5s;
}

.js-accordion.js>*>.accordion_header {
    cursor: pointer;
    visibility: visible;
}
.d-none {
    display: none;
}
.accordion_content:not(.collapsed) {
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
}

.accordion_content_inner {
    padding: 30px;
    font-size: 0.875rem;
    background: #fff;
}

.js-accordion.js>* {
    overflow: hidden;
}

.js-accordion section {
    margin-bottom: 10px;
}


#service-landing-c-row .accordion_header{
    padding: 10px;
    font-size: 1.4rem;
}

#service-landing-c-row .accordion_header .count_num{
    margin-inline: 8px;
}

#service-landing-c-row .js-accordion section .accordion_header:before{
    content: none;
}

#service-landing-c-row .js-accordion section.expanded .accordion_header:before{
    content: none;
}

#service-landing-c-row .js-accordion section .accordion_header:after{
    font-family: 'FontAwesome';
    content: '\f105';
    color: #232323;
    font-size: 20px;
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -12px;
    border: 1px solid #232323;
    border-radius: 53%;
    width: 23px;
    height: 23px;
    padding-left: 8px;
    line-height: 21px;
}

#service-landing-c-row .js-accordion section.expanded .accordion_header:after{
    color: #fff;
    content: '\f107';
    border: 1px solid #fff;
    padding-left: 4px;
}

        /*accordion---*/


.big-title .customModuleTitle{
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 8px;
        font-size: 3rem;
        color: #202020;
        margin-bottom: 30px;
}

.big-title .customModuleTitle .titleFirst{
    font-weight: inherit;
}

.big-title .customModuleTitle b{
    font-weight: 900;
}


.newspaper_mod ol > li{
    margin-bottom: 30px;
}

.newspaper_mod ul li{
    list-style-type: disc;
}

.newspaper_mod .mod-intro-text{
    line-height: 32px;
}

.moduletable.newspaper_mod .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
        letter-spacing: .8rem;
        font-size: 3rem;
        color: #444444;
        font-weight: 300;
        margin-bottom: 20px;
}
.padding-services-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
}

.moduletable.newspaper_mod .customModuleTitle b{
    font-weight: 900;
}

.moduletable.newspaper_mod .customModuleTitle .titleFirst{
    font-weight: inherit;
}

#service-landing-d-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

#service-landing-d-row{
    background-position:  left bottom;
    background-size: contain;
}

#service-landing-e-row .moduletable .customModuleTitle .titleFirst{
    font-weight: inherit;
}

#service-landing-e-row .moduletable .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #202020;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 58px;
}

#service-landing-e-row .moduletable .customModuleTitle b {
    font-weight: 900;
}

#service-landing-e-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

.services_e_mobile_mod .mod-intro-text ol{
    padding-left: 0;
}

#service-landing-e-row .modulePretext{
    margin-bottom: 50px;
}

.services_e_mod .mod-item-heading{
    font-size: 1.125rem;
    color: #fff;
    background: #232323;
    border-radius: 10px;
    padding: 15px 30px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 30px;
    position: relative;
}

.services_e_mod .line1 .mod-item-heading:after,
.services_e_mod .line4 .mod-item-heading:after{
    content: ' ';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 14px solid #232323;
    position: absolute;
    right: -13px;
    top: 50%;
    margin-top: -10px;
}

.services_e_mod .line2 .mod-item-heading:after,
.services_e_mod .line5 .mod-item-heading:after{
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #232323;
    position: absolute;
    left: 50%;
    bottom: -13px;
    margin-left: -7px;
}

.services_e_mod .line3 .mod-item-heading:after{
    content: ' ';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid #232323;
    position: absolute;
    left: -13px;
    top: 50%;
    margin-top: -10px;
}

.services_e_mod .line6 .mod-item-heading:after{
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #232323;
    position: absolute;
    left: 50%;
    top: -13px;
    margin-left: -7px;
}


.services_e_mod .mod-intro-text, .services_e_mod .mod-full-text{
    line-height: 30px;
}

.services_e_mod .one-line ol{
    padding: 0;
    list-style-position:inside;
}

.services_e_mod  {
    margin: 0 0 1.5em;
    /* padding: 0;*/
    counter-reset: item;
}

.services_e_mod  li {
  margin: 0;
  padding: 0 0 0 28px;
  text-indent: -30px;
  list-style-type: none;
   counter-increment: item;
}

.services_e_mod  li:before {
  display: inline-block;
  width: 30px;
  padding-right: 10px;
  font-weight: bold;
  text-align: right;

  content: counter(item) ".";
}

.info-block,
.info-block-left
{
    background: #fff;
    border: 1px solid #656565;
    padding: 35px;
    display: block;
    position: relative;
}

.info-block:after{
    background-image: url(../images/info-button.svg);
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    position: absolute;
    top: -19px;
    right: -19px;
}

.info-block-left:after{
    background-image: url(../images/info-button.svg);
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    position: absolute;
    top: -19px;
    left: -19px;
}

.line3 .info-block:after{
    right: inherit;
    left: -19px;
}

.line1 .info-block{
    padding: 55px 35px;
}
.line2 .info-block{
    padding: 86px 35px;
}

.line4 .info-block{
    padding: 55px 35px;
}

.line5 .right-col .info-block{
    padding: 74px 35px;
}

.services_e_mod .left-col{
    padding-right: 25px;
}

.services_e_mod .right-col{
    padding-left: 25px;
}

.services_e_mod .one-line{
    margin-bottom: 10px;
}


#service-landing-f-row .moduletable .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 8px;
    font-size: 3rem;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 58px;
    max-width: 800px;
}

#service-landing-f-row .moduletable .customModuleTitle .titleFirst {
    font-weight: inherit;
}

#service-landing-f-row .moduletable .customModuleTitle b {
    font-weight: 900;
}

#service-landing-f-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

#service-landing-f-row .customLogo .customModuleTitle::before {
    content: url(../images/customTitleLogoW.png);

    }

.services_f_mod .custom{
    color: #fff;
    font-size-adjust: 1.25rem;
    font-weight: 500;
}

#service-landing-f-row .row-container{
    position: relative;
    z-index: 2;
}

#service-landing-f-row{
    position: relative;
}

#service-landing-g-row {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.section-container .row-container > .container, .section-container .row-container > .container-fluid {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
#service-landing-g-row .mod-full-text {
    background: #F7F7F7;
    border: 1px solid #656565;
    color: #656565;
    font-size: 1rem;
    font-weight: 400;
    padding: 35px;
    display: block;
    position: relative;
    line-height: 30px;
}

#service-landing-g-row .mod-full-text:after{
    background-image: url(../images/info-button.svg);
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: "";
    position: absolute;
    top: -19px;
    left: -19px;
}

#service-landing-g-row .mod-full-text sup{
    font-size: 26px;
    top: 0;
}

.services_g_mod ul{
    list-style: none;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.services_g_mod ul li{
    position: relative;
}

.services_g_mod ul li:before{
     background-image: url(../images/verified.svg);
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: -50px;
    top: 15px;
    margin-top: -15px;
}

.services_g_mod .mod-intro-text li{
    font-size: 0.875rem;
    margin-bottom: 5px;
    color: #444444;
}

.services_g_mod .mod-intro-text li h4{
    font-family: "Source Sans Pro", sans-serif;
    /* letter-spacing: 4px; */
    font-size: 1.125rem;
    text-transform: uppercase;
    /* font-weight: 600; */
    color: #1f1f1f;
    margin-bottom: 1rem;
}


#service-landing-h-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

/* #service-landing-h-row .moduletable .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #202020;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 58px;
} */
/* .services_g_mod .moduleTitle .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #202020;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 58px;
} */

#service-landing-h-row .moduletable .customModuleTitle .titleFirst {
    font-weight: inherit;
}

.moduletable .customModuleTitle b {
    font-weight: 900;
}

.services_h_mod .modulePretext{
    text-align: center;
    margin-bottom: 50px;
    line-height: 32px;
}

.services_h_mod .landing-price-block .mod-intro-text{
    background: #fff;
    padding: 25px;
    margin: 10px 10px 0 10px;
    font-family: "Source Sans Pro", sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #232323;
    line-height: 30px;
    letter-spacing: 3px;
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
}

.services_h_mod .landing-price-block .mod-item-heading .mod-item-title{
    font-family: "Source Sans Pro", sans-serif;
    background: #222222;
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    font-weight: 300;
    padding: 30px 10px;
    margin: 0;
}

.services_h_mod .landing-price-block .mod-full-text{
    background: #fff;
    padding: 25px 15px;
    margin: 0 10px 0 10px;
    font-size: 0.875rem;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
}

.services_h_mod .landing-price-block .full-text-wrapper{
    background: #D7D7D7;
    padding: 15px;
}

.services_h_mod .landing-price-block .mod-item-heading{
    margin: 0;
}

.moduletable.services_h_mod{
    padding-bottom: 100px;
}

.services_h_mod .custom-urls{
    margin-top: 60px;
}

.services_h_two_mod .mod-intro-text{
    line-height: 32px;
    margin: 0;
}

.services_h_two_mod .mod-intro-text p:last-child{
    margin-bottom: 0;
}

.services_h_two_mod .title-line {
     font-family: "Source Sans Pro", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #232323;
}

.services_h_two_mod .info-block{
    line-height: 30px;
}

.services_h_two_mod .info-block:after{
    background-image: url(../images/info-button.svg);
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: "";
    position: absolute;
    top: -19px;
    left: -19px;
}

#service-landing-i-row .moduletable .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 58px;
}

#service-landing-i-row .moduletable .customModuleTitle .titleFirst {
    font-weight: inherit;
}

#service-landing-i-row .moduletable .customModuleTitle b {
    font-weight: 900;
}

#service-landing-i-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

#service-landing-i-row .customLogo .customModuleTitle::before {
    content: url(../images/customTitleLogoW.png);

    }

.service-landing-j {
    background-size: inherit !important;
    background-repeat: repeat-y !important;
}

.faq_module_j{
    padding: 65px 80px 65px 15px;
}

#service-landing-j-row .moduletable.form_faq.side_form .customModuleTitle {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 7px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 58px;
}

#service-landing-j-row .moduletable.form_faq.side_form  .titleFirst {
    font-weight: inherit;
}

#service-landing-j-row .moduletable.form_faq.side_form  b {
    font-weight: 900;
}

.moduletable.form_faq.side_form .pretext{
    color: #fff;
}

.moduletable.form_faq.side_form{
    padding: 50px 0 65px 80px;
}

.moduletable.form_faq.side_form .form-control{
    color: #fff;
}

.moduletable.form_faq.side_form  input.form-control::placeholder,
.moduletable.form_faq.side_form  textarea.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b9b9b9;
    font-weight: 300;
    opacity: 1; /* Firefox */
}

.moduletable.form_faq.side_form  input.form-control:-ms-input-placeholder,
.moduletable.form_faq.side_form  textarea.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b9b9b9;
    font-weight: 300;
}

.moduletable.form_faq.side_form  input.form-control::-ms-input-placeholder,
.moduletable.form_faq.side_form  textarea.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #b9b9b9;
    font-weight: 300;
}

.moduletable.form_faq.side_form .btn.btn-success.mod_its_form_btn{
    border: 1px solid #fff;
}



/* #service-landing-k-row .customModuleTitle{
   font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #212121;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 58px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
} */

#service-landing-k-row .customModuleTitle .titleFirst {
    font-weight: inherit;
}

#service-landing-k-row .customModuleTitle  b {
    font-weight: 900;
}

.why_rats_mod ul{
    list-style: none;
    padding-left: 60px;
}

.why_rats_mod ul li{
    position: relative;
}

.left_bullet{
    position: relative;
}

.why_rats_mod ul li .left_bullet:before{
     background-image: url(../images/verified.svg);
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: -60px;
    top: 50%;
    margin-top: -15px;

}

.why_rats_mod .mod-intro-text li{
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.125rem;
    margin-bottom: 30px;
    color: #212121;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
}

.tip_right{
    display: flex;
    align-items: center;
}

.tip_left{
    display: flex;
    align-items: center;
}

.why_rats_mod ul li.tip_left:before{
    left:50%;
}

.black_bkgr_right{
    background-color: #232323;
    color: #fff;
    max-width: 60%;
    padding: 15px 30px;
    border-radius: 10px;
    margin-left: 30px;
    position: relative;
    text-transform: none;
    letter-spacing: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
}

.black_bkgr_left{
    background-color: #232323;
    color: #fff;
    max-width: 60%;
    padding: 15px 30px;
    border-radius: 10px;
    margin-right: 90px;
    margin-left: -60px;
    position: relative;
    text-transform: none;
    letter-spacing: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
}

.black_bkgr_down{
    background-color: #232323;
    color: #fff;
    padding: 15px 30px;
    border-radius: 10px;
    position: relative;
    text-transform: none;
    letter-spacing: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
    display: block;
    margin-top: 25px;
    margin-bottom: 35px;
}

.black_bkgr_down:after
{
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #232323;
    position: absolute;
    left: 50%;
    bottom: -13px;
    margin-left: -7px;
}

.black_bkgr_right:after
{
    content: ' ';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid #232323;
    position: absolute;
    left: -13px;
    top: 50%;
    margin-top: -10px;
}

.black_bkgr_left:after
{
    content: ' ';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 14px solid #232323;
    position: absolute;
    right: -13px;
    top: 50%;
    margin-top: -10px;
}

.why_rats_mod .custom-urls{
    margin-top: 60px;
}

#service-landing-l-row .customModuleTitle{
   font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 8px;
    font-size: 3rem;
    color: #212121;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 58px;

}

#service-landing-l-row .customModuleTitle .titleFirst {
    font-weight: inherit;
}

#service-landing-l-row .customModuleTitle  b {
    font-weight: 900;
}

#service-landing-l-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

.categories_list_mod .cat_col a{
    height: 250px;
    background: #efefef;
    padding: 1.5rem;
}
.categories_list_mod .cat_col a:hover{
    box-shadow: 0px 3px 10px -1px rgba(100, 100, 100, 0.3)
}

.categories_list_mod .sub_element_a_inner{
    margin: auto;
}

.categories_list_mod  .mod-category-heading {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    color: #222222;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 2rem;
    text-transform: uppercase;
}

.categories_list_mod .cat_col i {
    font-size: 3rem;
    margin-bottom: 2.5rem;
}

.categories_list_mod .modulePretext {
    font-size: 0.875rem;
    font-weight: 300;
    color: #656565;
    margin-bottom: 50px;
}

#service-landing-m-row .row-container{
    padding-top: 0px;
}


#service-landing-n-row .customModuleTitle{
   font-family: "Source Sans Pro", sans-serif;
    letter-spacing: .8rem;
    font-size: 3rem;
    color: #212121;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 58px;
}

#service-landing-n-row .also_mod .customModuleTitle{
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 3px;
}

#service-landing-n-row .customModuleTitle .titleFirst {
    font-weight: inherit;
}

#service-landing-n-row .customModuleTitle  b {
    font-weight: 900;
}

#service-landing-n-row .customLogo .customModuleTitle::before {
    padding: 0px 6px 0px 8px;
}

/*.services_n_mod .mod-intro-text,
.services_n_mod .mod-full-text{
    width: 50%;
}*/

.services_n_mod .black_bkgr_left,
.services_n_mod .black_bkgr_right{
    max-width: 100%;
    margin: 0;
    display: block;
}

.services_n_mod  .info-block{
    margin-left: 30px;
}

.services_n_mod  .info-block-left{
    margin-right: 30px;
}

.services_n_mod .mod-intro-text p,
.services_n_mod .mod-full-text p{
    margin-bottom: 30px;
}

.also_mod  ul {
    list-style: none;
    padding-left: 60px;
}

.also_mod .mod-intro-text li {
    font-size: 1rem;
    margin-bottom: 26px;
    color: #656565;
    position: relative;
}

.also_mod ul li:before {
    background-image: url(../images/verified.svg);
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    left: -60px;
    top: 15px;
    margin-top: -15px;
}

#service-landing-n-row{
    background: #f7f7f7;
}

.also_mod .custom-urls .btn small{
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 700;
}

.also_mod .custom-urls .btn.btn-dark{
    font-size: 1.875rem;
    font-weight: 400;
    padding: 15px 70px;
    border-radius: 60px;
}

body.landing_page #content-row{
     background: url(../images/spiral2t.png) top 6% right;

     background-repeat: no-repeat;
}

#service-landing-a-row .row-container{
     background: url(../images/spiral1.png);
      background-position: left -45px bottom -500px;
     background-repeat: no-repeat;
}

#service-landing-b-row .row-container{
     background: url(../images/spiral1t.png);
      background-position: left -45px top -570px;
     background-repeat: no-repeat;
}

#service-landing-c-row .row-container{
     background:
                    url(../images/spiral1.png) left 200px ,
                    url(../images/spiral2.png) right -10%  top;

     background-repeat: no-repeat;
}

#service-landing-d-row .row-container{
     background: url(../images/spiral2tv.png) right -200px;
    background-repeat: no-repeat;
}

#service-landing-e-row .row-container{

    background:
                    url(../images/spiral1.png) left 300px ,
                     url(../images/spiral1.png) left -100px bottom -99px,
                    url(../images/spiral2.png) right -10%  top,
                    url(../images/spiral2.png) right -10%  bottom 1000px,
                    url(../images/spiral2.png) right -10%  bottom -300px;

     background-repeat: no-repeat;
}

#service-landing-f-row .row-container{
    background: url(../images/spiral2.png) right -10%  top -502px,
                url(../images/spiral1.png) left -100px top -972px ;
    background-repeat: no-repeat;
}

/* #service-landing-h-row .row-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: url(../images/spiral2.png) right -175px  top -10%,
                url(../images/spiral1.png) left -100px bottom 300px ;
    background-repeat: no-repeat;
} */

#service-landing-i-row .row-container{
    background: url(../images/spiral2dv.png) left 0  top -370px,
                url(../images/spiral2tfull.png) right -150px  bottom -434px;
    background-repeat: no-repeat;
}

#service-landing-j-row .row-container{
    background: url(../images/spiral2tfull.png) right -150px  top -368px;

    background-repeat: no-repeat;
}

#service-landing-e-row .row-container{
    padding-top: 2rem;
    padding-bottom: 2rem;

    background:
                    url(../images/spiral1.png) left 300px ,
                    url(../images/spiral1.png) left -100px bottom -99px,
                    url(../images/spiral2.png) right -10%  top,
                    url(../images/spiral2.png) right -10%  bottom 1000px,
                    url(../images/spiral2.png) right -10%  bottom -300px;

     background-repeat: no-repeat;
}

#service-landing-n-row .row-container{
    padding-top: 2rem;
    padding-bottom: 2rem;

    background:
                    url(../images/spiral1.png) left 300px ,
                     url(../images/spiral1.png) left -100px bottom -99px,
                    url(../images/spiral2.png) right -10%  top,
                    url(../images/spiral2.png) right -10%  bottom 1000px,
                    url(../images/spiral2.png) right -10%  bottom -300px;

     background-repeat: no-repeat;
}

body.landing_page #bottom-b-row .row-container{
    background: url(../images/spiral2.png) right -10%  top -502px;

     background-repeat: no-repeat;
}

body.landing_page #bottom-b-row .form-control{
    background: transparent;
}


/*Landing page end  -------------------------------------------------------*/
